/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

    // @font-face {
//   font-family: Yanone Kaffeesatz;
//   src: url(assets/fonts/YanoneKaffeesatz-Thin.otf) format("opentype");
// }

body {
  @include mat.elevation-transition;
  margin: 0;
  font-size: 16px;
  overflow: hidden;
}

a, a:hover, a:visited, a:hover:visited {
  text-decoration: inherit;
  color: inherit;
  transition: all .25s;
}


@font-face {
  font-family: "Source Sans Pro Light";
  src: url(assets/fonts/SourceSansPro-Light.otf) format("opentype");
}

@font-face {
  font-family: Source Sans Pro;
  src: url(assets/fonts/SourceSansPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Source Sans Pro Bold";
  src: url(assets/fonts/SourceSansPro-Bold.otf) format("opentype");
}

@font-face {
  font-family: Folks;
  src: url(assets/fonts/Folks-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url(assets/fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  src: url(assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: url(assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: digital7;
  src: url(assets/fonts/digital-7-mono.ttf) format("truetype");
}

.loading-spinner-container {
  height: 100%;
  background: #2B5DB044;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}

.edit-window-container {
  height: 100%;
  background: #2B5DB044;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.color-alternate {
  background: #DEF5;
}

.admin-popup {
  width: 600px;
  background: white;
  text-align: center;
  z-index: 1011;
}

.admin-popup-add {
  width: calc(100vw - 60px);
  height: calc(100vh - 200px);
  top: 140px;
  left: 20px;
  background: white;
  text-align: center;
  z-index: 1011;
  position: relative;
}

.admin-card {
  margin: 10px;
  padding: 10px;
  background: white;
}

.admin-no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}

.admin-y-overflow {
  overflow-y: auto;
  overflow-anchor: none;
}

.admin-line {
  height: 50px;
}

.admin-header {
  height: 80px;
}

.admin-line-item {
  height: 30px;
}

.admin-line-item-full-width {
  width: 100%;
}

.admin-line-border {
  border-bottom: #2b5db066 1px solid;
}

.admin-line-border-dotted {
  border-bottom: #2b5db066 1px dotted;
}

.admin-line-border-bold {
  border-bottom: #2b5db066 2px solid;
}

.admin-line-top-border-bold {
  border-top: #2b5db066 2px solid;
}

.admin-left-border {
  border-left: #2b5db066 1px solid;
}

.admin-lines-container {
  height: calc(100% - 80px);
}

.admin-lines-container-double {
  height: calc(100% - 160px);
}

.admin-root-card {
  height: calc(100% - 120px);
}

.admin-root-card-no-margin {
  height: calc(100% - 80px);
}

.admin-icon-2x, .admin-icon-2x:visited, .admin-icon-2x:hover {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.admin-icon, .admin-icon:visited, .admin-icon:hover {
   height: 30px;
   width: 30px;
   border-radius: 15px;
   cursor: pointer;
   text-decoration: none;
}

.admin-icon-small, .admin-icon-small:visited, .admin-icon-small:hover {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.admin-blue-icon, .admin-blue-icon:visited {
  background: #2B5DB0;
  color: #fff;
}

.admin-blue-icon:hover {
  background: #2B5DB0CC;
  color: #fff;
}

.admin-green-icon, .admin-green-icon:visited {
  background: #50fa7b;
  color: #fff;
}

.admin-green-icon:hover {
  background: #50fa7bCC;
  color: #fff;
}

.admin-orange-icon, .admin-orange-icon:visited {
  background: #ffb86c;
  color: #fff;
}

.admin-orange-icon:hover {
  background: #ffb86cCC;
  color: #fff;
}

.admin-white-icon, .admin-white-icon:visited {
  background: #fff;
  color: #2B5DB0;
}

.admin-white-icon:hover {
  background: #ffffffcc;
  color: #2B5DB0;
}

.admin-transparent-icon, .admin-transparent-icon:visited {
  background: #00000000;
  color: #fff;
}

.admin-transparent-icon:hover {
  background: #00000000;
  color: #fff;
}

.admin-red-icon, .admin-red-icon:visited {
  background: #FF0000;
  color: #fff;
}

.admin-red-icon:hover {
  background: #FF0000AA;
  color: #fff;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
  margin-top: 25px;
}

.container {
  padding: 5% 10%;
}

.container-no-top {
  padding: 0 10% 5% 10%;
}

.container-no-bottom {
  padding: 5% 10% 0 10%;
}

.container-no-top-bottom {
  padding: 0 10%;
}

.button {
  text-transform: uppercase;
  border: 2px solid #2B5DB0;
  border-radius: 10px;
  background: #2B5DB0;
  color: #fff;
  padding: 8px;
  font-size: 18px;
  font-family: "Folks";
  letter-spacing: 0.1em;
  // box-shadow: 0px 0px 20px #000;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  color: #fff;
}

.button-disabled {
  border: 2px solid #999999 !important;
  background: #999999 !important;
  cursor: default !important;
}

.light-blue {
  // background: rgb((244+210+210)/3, (247+235+235)/3, (249+243+243)/3);//#F4F7F9;
  // background: #4bb8ff;
  background: #def;
  border-radius: 10px;
}

.blue {
  // background: #D2EBF3;
  // background: #7acaff;
  background: #bcf;
  border-radius: 10px;
}

.container-no-padding {
  width: 100%;
  padding: 0;
}

.image-container {
	height: calc(100vh - 50px);
}

.image-keep-ratio {
  width: 100%;
  height: auto;
}

.card-image {
	width: 100%;
}

.header {
	font-size: 60px;
	font-family: "Yanone Kaffeesatz";
	color: #333;
	text-align: center;
  font-weight: 100;
}

.secondary-header {
	font-size: 20px;
	font-family: "Yanone Kaffeesatz";
	color: #333;
	text-align: center;
  font-weight: 100;
}

.full-height {
  height: 100%;
}

.top-margin {
  margin-top: 2.5%;
}

.mirror {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

// UNUSED :

.card-60 {
  float: left;
  width: 60%;
  min-width: 360px;
}

.card-50 {
  float: left;
  width: 50%;
  min-width: 300px;
}

.card-55 {
  float: left;
  width: 55%;
  min-width: 330px;
}

.card-40 {
  float: left;
  width: 40%;
	min-width: 240px;
}

.card-35 {
  float: left;
  width: 35%;
	min-width: 210px;
}

.card-30 {
	float: left;
	width: 30%;
	min-width: 180px;
}
.card-22 {
	float: left;
	width: 22%;
	min-width: 120px;
}

.card-20 {
	float: left;
	width: 20%;
	min-width: 120px;
}

.card-10 {
	float: left;
	width: 10%;
  min-height: 1px;
}

.card-5 {
	float: left;
	width: 5%;
  min-height: 1px;
}

.card-4 {
	float: left;
	width: 4%;
  min-height: 1px;
}

.card-y-600 {
  height: 600px;
}

.left-margin-10 {
  margin-left: 10%;
}

.right-margin-10 {
  margin-right: 10%;
}

.left-margin-5 {
  margin-left: 5%;
}

.right-margin-5 {
  margin-right: 5%;
}

.left-margin-2-5 {
  margin-left: 2.5%;
}

.right-margin-2-5 {
  margin-right: 2.5%;
}

.separator {
  height: 175px;
}

.line-60-3 {
  width: 60px;
  height: 3px;
  margin-top: 15px;
  background: #E5E5E5;
  display: block;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Fix material being a fucking moron
.mat-radio-label{
  vertical-align: top !important;
}
